import React from 'react'

export default function TextBanner({data,page}) {


  console.log(data);
    let finalData= data?.schema_json;  
    let content= {
        // sub_heading: 'What is ProLon®?',
        // heading: '',
        // description:'ProLon® is a 5-day dietary program carefully designed to nourish and rejuvenate your body while supporting metabolic balance. The ProLon FastingMimicking Diet® gives many of the benefits of a fast while letting you eat delicious food to help fight hunger and lose weight while protecting lean body mass. Get a trimmer waistline and many of the associated benefits of fasting without requiring ongoing lifestylechanges, such as long-term dieting.*',
       ...finalData
  }  
    return (
      <div class="main md:px-16 pb-8 p-4 -mt-8 flex flex-col justify-center items-center bg-white">
      <h1 class="font-bold text-xl">{content.heading}</h1>
      <p class="text-center font-normal text-md">{content.description}</p>
      </div>

    )
}
